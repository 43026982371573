import api from '@/services/Api'

export const FETCH_ORGANISATIONS_URL = '/staff/organisations'
export const FETCH_ORGANISATION_MEMBERS_URL = (organisationId) => `/staff/organisations/${organisationId}/members`
export const FETCH_MEMBERS_URL = '/staff/users'

export const StaffApiService = {
  fetchOrganisations () {
    return api.getData(FETCH_ORGANISATIONS_URL)
  },
  fetchOrganisationMembers (organisationId) {
    return api.getData(FETCH_ORGANISATION_MEMBERS_URL(organisationId))
  },
  fetchMembers () {
    return api.getData(FETCH_MEMBERS_URL)
  },
  generateTokenForUser (userId) {
    return api.postData(`staff/users/${userId}/generate-short-lived-token`)
  }
}
