<template>
  <page-section class="ImpersonateUser" page-title="Impersonate a user">
    <template #above-title>
      <dashboard-filters-v2>
        <template #primary>
          <form-field
            left-icon="search"
            icon-class="hf-lg"
          >
            <input
              v-model.trim="query.search"
              placeholder="Search by User first & last name or email address or Organisation Name, UUID"
              type="text"
              class="input is-transparent is-size-5 has-text-weight-semibold"
              data-testid="userSearchInput"
            >
          </form-field>
        </template>
      </dashboard-filters-v2>
    </template>
    <div class="columns has-text-weight-bold">
      <div class="column">
        User Name
      </div>
      <div class="column is-4">
        Organisation
      </div>
      <div class="column is-3 has-text-centered">
        Action
      </div>
    </div>
    <load-more :endpoint="membersUrl" :debounce="500">
      <template #item="{ item: user }">
        <div class="User columns is-variable-grid is-2 m-b-s">
          <div class="column is-narrow">
            <round-avatar :src="user.avatar.cropped" size="xsmall" />
          </div>
          <div class="column">
            <div>
              {{ user.full_name }}
            </div>
            <div class="is-size-7 has-text-weight-semibold">
              {{ user.email }}
            </div>
          </div>
          <div class="column is-4">
            <div>
              {{ user.organisation.data.name }}
            </div>
            <div class="is-size-7 has-text-weight-semibold">
              UUID: {{ user.organisation.data.id }}
            </div>
          </div>
          <div class="column is-3 has-text-centered">
            <v-button
              :loading="isGeneratingToken === user.id"
              :disabled="impersonated"
              class="is-black"
              data-testid="impersonateUserTrigger"
              @click="impersonate(user.id)"
            >
              <span>Impersonate</span>
            </v-button>
          </div>
        </div>
      </template>
    </load-more>
  </page-section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { buildUrlWithParams } from '@hypefactors/shared/js/utils/UrlUtils'
import { FETCH_MEMBERS_URL, StaffApiService } from '@/services/api/StaffApiService'

/**
 * @module ImpersonateUser
 */
export default {
  name: 'ImpersonateUser',
  data () {
    return {
      isGeneratingToken: null,
      isFetchingUsers: false,
      query: {
        search: '',
        include: ['organisation']
      }
    }
  },
  computed: {
    ...mapGetters(['impersonated', 'authUserId']),
    membersUrl () {
      return buildUrlWithParams(FETCH_MEMBERS_URL, this.query)
    }
  },
  methods: {
    ...mapActions(['impersonateUser']),
    async impersonate (userId) {
      try {
        this.isGeneratingToken = userId
        const { token } = await StaffApiService.generateTokenForUser(userId)
        await this.impersonateUser(token)
        this.$router.push({ name: 'dashboard.dashboard' })
      } catch (err) {
        this.$displayRequestError(err)
      } finally {
        this.isGeneratingToken = null
      }
    }
  }
}
</script>
